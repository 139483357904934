import React, { FunctionComponent } from "react";
import Image, { CommonImageProps } from "./Image";
import { useI18next } from "./Translation";

type CommonNode = {
  relativePath: string;
};
type RasterNode = {
  childImageSharp: { fluid: any };
};
type VectorNode = {
  publicURL: string;
};

type ImageListProps = {
  images: {
    nodes: (CommonNode & (RasterNode | VectorNode))[];
  };
};
type TranslatedImageProps = ImageListProps & CommonImageProps;
const TranslatedImage: FunctionComponent<TranslatedImageProps> = ({
  images,
  ...props
}) => {
  const { language } = useI18next();

  const first = images.nodes[0];
  const currentImage =
    images.nodes.find((node) => node.relativePath.match("^" + language)) ||
    first;

  if (currentImage.childImageSharp !== undefined) {
    return <Image fluid={currentImage.childImageSharp.fluid} {...props} />;
  }
  return <img src={currentImage.publicURL} {...props} />;
};

export default TranslatedImage;
