import React, { FunctionComponent } from "react";
import { graphql, useStaticQuery } from "gatsby";
import Translation, { useTranslation } from "../../../Translation";
import Image from "../../../Image";
import Link from "../../../Link";

const SeeDamage: FunctionComponent = () => {
  const data = useStaticQuery(
    graphql`
      query {
        img: file(base: { eq: "img2.png" }) {
          ...DesktopImage
        }
      }
    `
  );

  return (
    <Link
      href="/what-is-hep-b/effects-on-the-liver/"
      analytics="Calls to Action,Clicked,See the liver damage Hep B can cause"
    >
      <div className="mb-1 flex flex-col-reverse laptop:flex-col h-full">
        <div className="bg-grey-light">
          <Image fluid={data.img.childImageSharp.fluid} objectFit="cover" />
        </div>
        <div className="bg-green text-white font-bold uppercase text-center desktop:px-20 flex justify-center items-center">
          <Translation id="see-liver-damage" />
        </div>
      </div>
    </Link>
  );
};

export default SeeDamage;
