import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Translation from "../../Translation";
import Image from "../../Image";
import LearnMore from "./Panels/LearnMore";
import ConsiderTreatment from "./Panels/ConsiderTreatment";
import SeeDamage from "./Panels/SeeDamage";
import LearnTruth from "./Panels/LearnTruth";
import WatchVideos from "./Panels/WatchVideos";
import TranslatedImage from "../../TranslatedImage";
import Link from "../../Link";

const Homepage = () => {
  const data = useStaticQuery(
    graphql`
      query {
        img1: allFile(filter: { base: { eq: "img1.png" } }) {
          nodes {
            relativePath
            ...MobileImage
          }
        }
        img2: file(base: { eq: "img2-mobile.png" }) {
          ...MobileImage
        }
        img3: allFile(filter: { base: { eq: "img3.jpg" } }) {
          nodes {
            relativePath
            ...DesktopImage
          }
        }
        img4: file(base: { eq: "img6-mobile.png" }) {
          ...DesktopImage
        }
        img5: file(base: { eq: "img5-mobile.png" }) {
          ...DesktopImage
        }
      }
    `
  );

  return (
    <section id="home" className="bg-grey-light p-4 laptop:p-8">
      <div className="container mx-auto laptop:px-1 bg-white shadow-xl">
        <div className="hidden laptop:block">
          <div className="flex w-full justify-items-stretch pt-1">
            <div className="w-1/3 h-full">
              <LearnMore />
              <ConsiderTreatment />
            </div>
            <div className="w-1/3 h-full ml-1">
              <SeeDamage />
              <LearnTruth />
            </div>
            <div className="w-1/3 h-full ml-1">
              <WatchVideos />
            </div>
          </div>
        </div>
        <div className="laptop:hidden">
          <div className="grid grid-cols-2 grid-rows-3 gap-1 my-4">
            <div className="h-48 overflow-hidden">
              <Link
                href="/what-is-hep-b"
                analytics="Calls to Action,Clicked,Learn more about the Hepatitis B virus"
              >
                <div className="relative bg-green text-white font-bold uppercase text-center flex justify-center items-center h-16 leading-none z-10">
                  <Translation id="learn-more" />
                </div>
                <div className="h-32" style={{ width: "110%" }}>
                  <TranslatedImage
                    images={data.img1}
                    objectFit="cover"
                    style={{ marginLeft: "-10%" }}
                  />
                </div>
              </Link>
            </div>
            <div className="h-48 overflow-hidden relative">
              <Link
                href="/what-is-hep-b/effects-on-the-liver/"
                analytics="Calls to Action,Clicked,See the liver damage Hep B can cause"
              >
                <div className="relative bg-green text-white font-bold uppercase text-center flex justify-center items-center h-16 leading-none z-10 see-liver-damage">
                  <Translation id="see-liver-damage" />
                </div>
                <Image
                  fluid={data.img2.childImageSharp.fluid}
                  objectFit="cover"
                  className="absolute right-0 bottom-0 left-0 h-32"
                />
              </Link>
            </div>

            <div className="h-48 overflow-hidden col-span-2 relative">
              <Link
                href="/living-with-hep-b/hep-b-facts/"
                analytics="Calls to Action,Clicked,Learn the truth about Hep B myths"
              >
                <div className="relative bg-green text-white font-bold uppercase text-center justify-center flex items-center h-16 leading-none z-10">
                  <Translation id="learn-truth-about" />
                </div>
                <TranslatedImage
                  images={data.img3}
                  objectFit="cover"
                  className="absolute top-0 right-0 bottom-0 left-0"
                />
              </Link>
            </div>
            <div className="h-48 overflow-hidden relative">
              <Link
                href="/considering-hep-b-treatment/"
                analytics="Calls to Action,Clicked,Consider treatment for Hep B"
              >
                <div className="relative bg-green text-white font-bold uppercase text-center flex justify-center items-center h-16 leading-none z-10">
                  <Translation id="consider-treatment" />
                </div>
                <Image
                  fluid={data.img4.childImageSharp.fluid}
                  objectFit="cover"
                />
              </Link>
            </div>
            <div className="h-48 overflow-hidden relative">
              <Link
                href="/resources/videos"
                analytics="Calls to Action,Clicked,Explainer video: What is Hep B?"
              >
                <div className="relative bg-green text-white font-bold uppercase text-center flex  justify-center items-center h-16 leading-none z-10">
                  <Translation id="watch-videos" />
                </div>
                <Image
                  fluid={data.img5.childImageSharp.fluid}
                  objectFit="cover"
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Homepage;
