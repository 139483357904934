import React, { FunctionComponent } from "react";
import { graphql, useStaticQuery } from "gatsby";
import Translation, { useTranslation } from "../../../Translation";
import Image from "../../../Image";
import Link from "../../../Link";

const WatchVideos: FunctionComponent = () => {
  const data = useStaticQuery(
    graphql`
      query {
        img: file(base: { eq: "img5.png" }) {
          ...DesktopImage
        }
      }
    `
  );

  return (
    <Link
      href="/resources/videos"
      analytics="Calls to Action,Clicked,Explainer video: What is Hep B?"
    >
      <div className="mb-1">
        <div className="bg-green text-white font-bold uppercase text-center px-4 py-4 flex justify-center items-center">
          <Translation id="watch-videos" />
        </div>
        <div className="bg-grey-light watch-videos ">
          <Image fluid={data.img.childImageSharp.fluid} />
        </div>
      </div>
    </Link>
  );
};

export default WatchVideos;
