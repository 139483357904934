import React, { FunctionComponent } from "react";
import { graphql, useStaticQuery } from "gatsby";
import Translation from "../../../Translation";
import Link from "../../../Link";
import TranslatedImage from "../../../TranslatedImage";

const LearnTruth: FunctionComponent = () => {
  const data = useStaticQuery(
    graphql`
      query {
        img3: allFile(filter: { base: { eq: "img3.png" } }) {
          nodes {
            relativePath
            ...DesktopImage
          }
        }
      }
    `
  );

  return (
    <Link
      href="/living-with-hep-b/hep-b-facts/"
      analytics="Calls to Action,Clicked,Learn the truth about Hep B myths"
    >
      <div className="mb-1 flex flex-col-reverse laptop:flex-col">
        <div
          className="bg-grey-light learn-truth"
        >
          <TranslatedImage images={data.img3} objectFit="cover" />
        </div>
        <div className="bg-green text-white font-bold uppercase text-center desktop:px-24 flex justify-center items-center">
          <Translation id="learn-truth-about" />
        </div>
      </div>
    </Link>
  );
};

export default LearnTruth;
