import React from "react";
import Layout from "../components/Layout/Layout";
import SEO from "../components/seo";
import Homepage from "../components/Markup/Homepage/Homepage";

const IndexPage = () => (
  <Layout>
    <SEO name="home" baseName="" />
    <Homepage />
  </Layout>
);

export default IndexPage;
