import React, { FunctionComponent } from "react";
import { graphql, useStaticQuery } from "gatsby";
import Translation, { useTranslation } from "../../../Translation";
import Image from "../../../Image";
import Link from "../../../Link";

const ConsiderTreatment: FunctionComponent = () => {
  const data = useStaticQuery(
    graphql`
      query {
        img: file(base: { eq: "img6.png" }) {
          ...DesktopImage
        }
      }
    `
  );

  return (
    <Link
      href="/considering-hep-b-treatment/"
      analytics="Calls to Action,Clicked,Consider treatment for Hep B"
    >
      <div className="mb-1">
        <div className="bg-green text-white font-bold uppercase text-center desktop:px-20 py-1 flex justify-center items-center">
          <Translation id="consider-treatment" />
        </div>
        <div className="bg-grey-light consider-treatment">
          <Image fluid={data.img.childImageSharp.fluid} objectFit="cover" />
        </div>
      </div>
    </Link>
  );
};

export default ConsiderTreatment;
