import React, { FunctionComponent } from "react";
import { graphql, useStaticQuery } from "gatsby";
import Translation, { useTranslation } from "../../../Translation";
import Image from "../../../Image";
import Link from "../../../Link";
import TranslatedImage from "../../../TranslatedImage";

const LearnMore: FunctionComponent = () => {
  const { t } = useTranslation();
  const data = useStaticQuery(
    graphql`
      query {
        img1: allFile(filter: { base: { eq: "img1.png" } }) {
          nodes {
            relativePath
            ...DesktopImage
          }
        }
      }
    `
  );

  return (
    <Link
      href="/what-is-hep-b"
      analytics="Calls to Action,Clicked,Learn more about the Hepatitis B virus"
    >
      <div className="mb-1">
        <div className="bg-green text-white font-bold uppercase text-center desktop:px-20 py-1 flex justify-center items-center">
          <Translation id="learn-more" />
        </div>
        <div className="bg-grey-light">
          <TranslatedImage images={data.img1} alt={t("if-left-untreated")} />
        </div>
      </div>
    </Link>
  );
};

export default LearnMore;
